import React from 'react';

import { AuditHistory } from '../../components/views/history/auditHistory/auditHistory';
import { LayoutLoggedIn } from '../../components/views/layout';

export default function() {
    return (
        <LayoutLoggedIn>
            <AuditHistory
                Action={0}
                startDate={'1 Jan 2020'}
            />
        </LayoutLoggedIn>
    );
}
