import React, { useRef, useState, useMemo, useEffect } from 'react'
import * as yup from 'yup'
import { format } from 'date-fns'
import { isEmptyObject } from '../../../../common/utils/functions'
import { TableWrapper } from '../../../common/table/TableWrapper'
import {
   Button,
   CsvDownloadForm,
   Filters,
   LoadingOverlay,
   Modal,
   PageHeader,
   Search,
   SlidePanel,
   Svg,
} from '../../../common'
import FilterButton from '../../../common/filters/filterButton/filterButton'
import FilterTags from '../../../common/filters/filterTags/filterTags'
import {
   DateRangeFilter,
   DropdownFilter,
} from '../../../common/filters/filterTypes'
import { Option } from '../../../common/form/fields/dropdown/dropdown.type'
import {
   convertItemStatusesToOptions,
   convertConnectionsToOptions,
   convertManufacturersToOptions,
   convertCategoriesToOptions,
   convertLocationToOptions,
   convertConditionsToOptions,
} from '../../../common/filters/helpers'

import { Actions, PAGINATION } from '../../../../constants/tables'
import { FORMATS } from '../../../../constants/datetime'

import { BASIC_TABLE } from './constants'

import * as styles from './auditHistory.module.scss'
import {
   useHistory,
   useFilters,
   useAuditStatus,
   useConnections,
   useManufacturers,
   useCategories,
   useActiveLocations,
   useConditions,
   useSettings,
} from '../../../../hooks'
import {
   Filters as FiltersObject,
   FiltersForAPI,
} from '../../../../hooks/useFilters/useFilters.type'
import { flattenFilterObj } from '../../../../hooks/useFilters/useFilters.helpers'

const {
   extraClassName,
   placeholder,
   tableTitle,
   downloadCsvText,
   basicPath,
   key,
   modalTitle,
   primaryActionText,
   secondaryActionText,
   exportFileName,
   columnHeaders,
   initialPayload,
   downloadRequest,
} = BASIC_TABLE

interface Props {
   Action: Actions
   startDate: any
}

const schema = yup.object().shape({
   sentStartDate: yup
      .date()
      .max(new Date(), 'Start Date cannot be in the future'),
   sentEndDate: yup.date().when(['sentStartDate'], (sentStartDate: Date) => {
      if (sentStartDate) {
         return yup
            .date()
            .min(
               sentStartDate,
               'End Date cannot be earlier than the Start Date'
            )
            .max(new Date(), 'End Date cannot be in the future')
      }
   }),
})

export function formatFiltersForAPI(filters: FiltersObject): FiltersForAPI {
   function formatDate(date: Date) {
      return format(date, FORMATS.filterDateAPI)
   }

   const startDateFilter = {
      ...(filters?.sentStartDate?.value instanceof Date && {
         sentStartDate: formatDate(filters?.sentStartDate?.value),
      }),
   }
   const endDateFilter = {
      ...(filters?.sentEndDate?.value instanceof Date && {
         sentEndDate: formatDate(filters?.sentEndDate?.value),
      }),
   }

   return {
      ...flattenFilterObj(filters),
      ...startDateFilter,
      ...endDateFilter,
   }
}

export const AuditHistory: React.FunctionComponent<Props> = () => {
   const { query, skip, take } = PAGINATION
   const { settings } = useSettings()

   const {
      CustomDate1Label,
      CustomDate1Enabled,
      CustomDate2Label,
      CustomDate2Enabled,
      CustomText1Label,
      CustomText1Enabled,
      CustomText2Label,
      CustomText2Enabled,
      CustomURL1Label,
      CustomURL1Enabled,
      CustomURL2Label,
      CustomURL2Enabled,
      CustomRentalRate1Label,
      CustomRentalRate1Enabled,
      CustomRentalRate2Label,
      CustomRentalRate2Enabled,
      CustomRentalRate3Label,
      CustomRentalRate3Enabled,
      CustomRentalRate4Label,
      CustomRentalRate4Enabled,
   } = settings

   const customAccessorsHeaderList = [
      {
         accessor: 'CustomRentalRate1',
         label: CustomRentalRate1Label,
         isEnabled: CustomRentalRate1Enabled,
      },
      {
         accessor: 'CustomRentalRate2',
         label: CustomRentalRate2Label,
         isEnabled: CustomRentalRate2Enabled,
      },
      {
         accessor: 'CustomRentalRate3',
         label: CustomRentalRate3Label,
         isEnabled: CustomRentalRate3Enabled,
      },
      {
         accessor: 'CustomRentalRate4',
         label: CustomRentalRate4Label,
         isEnabled: CustomRentalRate4Enabled,
      },
      {
         accessor: 'CustomDate1Value',
         label: CustomDate1Label,
         isEnabled: CustomDate1Enabled,
      },
      {
         accessor: 'CustomDate2Value',
         label: CustomDate2Label,
         isEnabled: CustomDate2Enabled,
      },
      {
         accessor: 'CustomText1Value',
         label: CustomText1Label,
         isEnabled: CustomText1Enabled,
      },
      {
         accessor: 'CustomText2Value',
         label: CustomText2Label,
         isEnabled: CustomText2Enabled,
      },
      {
         accessor: 'CustomURL1Value',
         label: CustomURL1Label,
         isEnabled: CustomURL1Enabled,
      },
      {
         accessor: 'CustomURL2Value',
         label: CustomURL2Label,
         isEnabled: CustomURL2Enabled,
      },
   ]

   const {
      data: itemData,
      list,
      setPath,
      setPageOptions,
      totalItems,
      isLoading,
      isRefetching,
   } = useHistory()

   const [searchMode, setSearchMode] = useState(false)
   const [pageSized, setPageSized] = useState(PAGINATION.pageSized)
   const [isPageReset] = useState(false)
   const [searchCriteria, setSearchCriteria] = useState('')
   const [fullDownload] = useState(downloadRequest)
   const [filteredDownload, setFilteredDownload] = useState(downloadRequest)
   const [isDownloading, setIsDownloading] = useState(false)

   const {
      errors,
      modify,
      reset,
      remove: removeFilter,
      save,
      savedFilters,
      tmpFilters,
   } = useFilters(schema, {})

   const buttonRef = useRef<HTMLInputElement>(null)

   const [filtersOpen, setFiltersOpen] = useState(false)
   const [filterValues, setFilterValues] = useState({})
   const formRef = useRef<HTMLFormElement>()
   const [isModalVisible, setIsModalVisible] = useState(false)

   // filter data
   const { list: auditStatuses } = useAuditStatus()
   const { list: connections } = useConnections()
   const { list: manufacturers } = useManufacturers()
   const { list: categories } = useCategories()
   const { list: locations } = useActiveLocations()
   const { list: conditions } = useConditions()

   const applySavedFilters = (filters: FiltersObject) => {
      setFiltersOpen(false)
      handleGetItems(searchCriteria, 0, pageSized, filters)
      setFilterValues(formatFiltersForAPI(filters))
   }

   const handleGetItems = (
      searchForAuditItem?: string,
      skip?: number,
      take?: number,
      filters?: FiltersObject
   ) => {
      const formattedFilters = formatFiltersForAPI(filters || savedFilters)
      setFilterValues(formattedFilters)

      list({
         searchForAuditItem: searchForAuditItem,
         sentStartDate: null,
         sentEndDate: null,
         selectedConditions: [],
         selectedConfrmationType: [],
         selectedItemConfirmation: 0,
         offset: -1,
         selectedConnections: [],
         selectedLocations: [],
         selectedCategories: [],
         selectedStatus: [],
         selectedManufacturers: [],
         skip: skip,
         take: take,
         ...formattedFilters,
      })
   }

   const handleClose = () => {
      setIsModalVisible(false)
      buttonRef.current.click()
   }

   const showDownloadModal = () => {
      setIsModalVisible(true)
   }

   const handleDropdownChange = (
      name: string,
      label: string,
      options: Option[]
   ) => {
      modify(
         name,
         label,
         options?.map((option) => ({
            label: option.label,
            value: option.value,
         }))
      )
   }

   const handleRemoveTag = (name: string, id: string): void => {
      const newFilters = removeFilter(name, id)
      applySavedFilters(newFilters)
      setFilterValues(formatFiltersForAPI(newFilters))
   }

   const handleFilterSave = async () => {
      const isSaved = await save()

      if (isSaved) {
         applySavedFilters(tmpFilters)
      }
   }

   const handleResetFilters = () => {
      formRef.current.reset()
      reset()
      applySavedFilters({})
   }

   const columns = useMemo(() => {
      const enabledCustomAccessorsHeaderList = customAccessorsHeaderList.filter(
         (accessor) => accessor.isEnabled
      )
      // create list of disabled accessors
      const disabledCustomAccessorsNameList = customAccessorsHeaderList
         .filter((accessor) => !accessor.isEnabled)
         .reduce((arr, obj) => [...arr, obj.accessor], [])
      // change custom header to match settings
      enabledCustomAccessorsHeaderList.map((customAccessor) => {
         const customMatch = columnHeaders.find(
            (header) => header.accessor === customAccessor.accessor
         )
         customMatch.Header = customAccessor.label
      })
      // disable any custom headers not enabled from settings
      const enabledHeaders = columnHeaders.filter(
         (header) => !disabledCustomAccessorsNameList.includes(header.accessor)
      )

      return enabledHeaders
   }, [itemData])

   const auditStatusOptions = convertItemStatusesToOptions(auditStatuses)
   const connectionsOptions = convertConnectionsToOptions(connections)
   const manufacturerOptions = convertManufacturersToOptions(manufacturers)
   const categoryOptions = convertCategoriesToOptions(categories)
   const locationOptions = convertLocationToOptions(locations)
   const conditionOptions = convertConditionsToOptions(conditions)

   useEffect(() => {
      setPath(basicPath, key)
      setPageOptions(initialPayload)
   }, [])

   useEffect(() => {
      const formattedFilters = formatFiltersForAPI(savedFilters)
      setFilterValues(formattedFilters)

      setFilteredDownload({
         ...downloadRequest,
         searchForAuditItem: searchCriteria,
         skip: 0,
         take: 10,
         ...formattedFilters,
      })
   }, [searchCriteria, savedFilters])

   return (
      <div>
         {(isLoading || isRefetching) && <LoadingOverlay />}
         <PageHeader title={tableTitle}>
            <Button
               onClick={showDownloadModal}
               preserveText
               variant="plain"
               disabled={!Boolean(itemData.length)}
               minWidth="0"
            >
               <Svg id="download" width={8} height={8} /> {downloadCsvText}
            </Button>
         </PageHeader>

         <div className={styles.searchWrapper}>
            <div className={styles.searchWrapperSearch}>
               <Search
                  handleQuery={handleGetItems}
                  searchMode={searchMode}
                  setSearchMode={setSearchMode}
                  pageSized={pageSized}
                  placeHolder={placeholder}
                  setSearchCriteria={setSearchCriteria}
               />
            </div>
            <div className={styles.searchWrapperFilter}>
               <FilterButton
                  isActive={!isEmptyObject(savedFilters)}
                  onClick={() => setFiltersOpen(!filtersOpen)}
               />
            </div>
         </div>

         <FilterTags filters={savedFilters} onClick={handleRemoveTag} />
         {!isLoading && (
            <TableWrapper
               isLoading={isLoading}
               data={itemData}
               columns={columns}
               totalCount={totalItems}
               getItems={handleGetItems}
               takeItems={PAGINATION.take}
               skipItems={PAGINATION.skip}
               setSearchMode={setSearchMode}
               searchMode={searchMode}
               query={query}
               isPageReset={isPageReset}
               setPageSized={setPageSized}
               extraClassName={extraClassName}
               searchCriteria={searchCriteria}
               setView={undefined}
            />
         )}
         <SlidePanel isOpen={filtersOpen} onClose={() => setFiltersOpen(false)}>
            <Filters onReset={handleResetFilters} onSave={handleFilterSave}>
               <form ref={formRef}>
                  <DateRangeFilter
                     dateProps={{
                        from: {
                           defaultValue: tmpFilters?.sentStartDate
                              ?.value as Date,
                           error: errors?.sentStartDate,
                           id: 'sentStartDate',
                           placeholder: 'Start date',
                        },
                        to: {
                           defaultValue: tmpFilters?.sentEndDate?.value as Date,
                           error: errors?.sentEndDate,
                           id: 'sentEndDate',
                           placeholder: 'End date',
                        },
                     }}
                     id="sentStartDate"
                     onChange={modify}
                     title="Date range"
                  />

                  <h3 className={styles.filterHeading}>Tool Records</h3>

                  <DropdownFilter
                     defaultValue={
                        tmpFilters?.selectedCategories?.value as Option[]
                     }
                     isMulti
                     id="selectedCategories"
                     onChange={handleDropdownChange}
                     options={categoryOptions}
                     title="Category"
                  />

                  <DropdownFilter
                     defaultValue={
                        tmpFilters?.selectedManufacturers?.value as Option[]
                     }
                     isMulti
                     id="selectedManufacturers"
                     onChange={handleDropdownChange}
                     options={manufacturerOptions}
                     title="Manufacturers"
                  />

                  <h3 className={styles.filterHeading}>Audit Records</h3>

                  <DropdownFilter
                     defaultValue={
                        tmpFilters?.selectedStatus?.value as Option[]
                     }
                     isMulti
                     id="selectedStatus"
                     onChange={handleDropdownChange}
                     options={auditStatusOptions}
                     title="Status"
                  />

                  <DropdownFilter
                     defaultValue={
                        tmpFilters?.selectedConnections?.value as Option[]
                     }
                     isMulti
                     id="selectedConnections"
                     onChange={handleDropdownChange}
                     options={connectionsOptions}
                     title="Connection"
                  />

                  {!!settings?.EnableLocationTracking && (
                     <DropdownFilter
                        defaultValue={
                           tmpFilters?.selectedLocations?.value as Option[]
                        }
                        isMulti
                        id="selectedLocations"
                        onChange={handleDropdownChange}
                        options={locationOptions}
                        title="Location"
                     />
                  )}

                  <DropdownFilter
                     defaultValue={
                        tmpFilters?.selectedConditions?.value as Option[]
                     }
                     isMulti
                     id="selectedConditions"
                     onChange={handleDropdownChange}
                     options={conditionOptions}
                     title="Condition"
                  />

                  <DropdownFilter
                     defaultValue={
                        tmpFilters?.selectedConfrmationType?.value as Option[]
                     }
                     isMulti
                     id="selectedConfrmationType"
                     onChange={handleDropdownChange}
                     options={[
                        { value: '2', label: 'Manual' },
                        { value: '1', label: 'Scan' },
                     ]}
                     title="Confirmation type"
                  />

                  <DropdownFilter
                     defaultValue={
                        tmpFilters?.selectedItemConfirmation?.value as Option[]
                     }
                     isMulti
                     id="selectedItemConfirmation"
                     onChange={handleDropdownChange}
                     options={[
                        { value: '1', label: 'Filter By Confirmation' },
                        { value: '2', label: 'Unconfirmed' },
                        { value: '3', label: 'Confirmed' },
                     ]}
                     title="Item confirmation"
                  />
               </form>
            </Filters>
         </SlidePanel>

         <Modal
            isModalVisible={isModalVisible}
            closeModal={handleClose}
            title={modalTitle}
         >
            <CsvDownloadForm
               primaryActionText={primaryActionText}
               secondaryActionText={secondaryActionText}
               criteria={''}
               data={itemData}
               fileName={exportFileName}
               closeModal={handleClose}
               path={basicPath.DOWNLOAD}
               query={searchCriteria}
               fullDownload={fullDownload}
               filters={filterValues}
               filteredDownload={filteredDownload}
               setIsDownloading={setIsDownloading}
               isDownloading={isDownloading}
               defaultParams={downloadRequest}
               buttonRef={buttonRef}
            />
         </Modal>
      </div>
   )
}
